<template>
  <v-app>
    <v-main>
       <v-img
      :class="{
        'v-image--sm': this.$vuetify.breakpoint.smAndDown,
        'v-image--md': this.$vuetify.breakpoint.mdAndDown
      }"
      :src="require(`@/assets/${'login.jpg'}`)"

      min-height="100%"
      max-height="100px"
    >
      <router-view />
          </v-img>

    </v-main>
  </v-app>
</template>

<script>
export default {
    name: 'DashboardCoreView',
    data: () => ({}),
    computed: {}
}
</script>
